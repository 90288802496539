import './App.css';

function App() {
    return (
        <div class="full center">
            <div id="title">
                [  David Santamaria  ]
            </div>
            <div id="linkbar">
                <div class="linkbar_item">
                    <a href="./portfolio" class="linkbar_link">portfolio</a>
                </div>
                <div class="linkbar_divider">
                </div>
                <div class="linkbar_item">
                    <a href="./resume" class="linkbar_link">resume</a>
                </div>
                <div class="linkbar_divider">
                </div>
                <div class="linkbar_item">
                    <a href="https://github.com/Daves1245" class="linkbar_link">github</a>
                </div>
                <div class="linkbar_divider">
                </div>
                <div class="linkbar_item">
                    <a href="http://codeforces.com/profile/medbar" class="linkbar_link">codeforces</a>
                </div>
                <div class="linkbar_divider">
                </div>
                <div class="linkbar_item">
                    <a href="./projects/" class="linkbar_link">projects</a>
                </div>
                <div class="linkbar_divider">
            </div>
        </div>
    </div>
  );
}

export default App;
